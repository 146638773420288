import React, { Component } from "react"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import CardSegment from "../../components/marketing/CardSegment"
import LeftContentCard from "../../components/marketing/LeftContentCard"
import RightContentCard from "../../components/marketing/RightContentCard"
import SubscribeAction from "../../components/marketing/SubscribeAction"
import Whatsapp from "../../components/marketing/Whatsapp"
import AboutBiodiesel from "../../images/about_biodiesel.webp"
import AboutMPOB from "../../images/about_mbop.webp"
import AboutNRE from "../../images/about_nre.webp"
import CustFactory from "../../images/cust_factory.webp"
import CustHouse from "../../images/cust_house.webp"
import CustRestaurant from "../../images/cust_restaurant.webp"
import DoorImage from "../../images/home_ss14.webp"
import StepCollect from "../../images/step_collect.webp"
import StepFilter from "../../images/step_filter.webp"
import StepLab from "../../images/step_lab.webp"
import { Language } from "../../store/reducers/utilsReducer"

interface Props {
  authStore: any
  updatePromotionCode: (promoCode: string) => void
}

const workPosts = [
  {
    title: "第一步：收集",
    desc:
      "提供上门服务，从住宅区/工厂/餐厅收集您用过的食用油。通过在 Arus Oil 手机程序预约让我们知道",
    img: StepCollect,
  },
  {
    title: "第二步：过滤",
    desc:
      "从您那里收集的用过的食用油将从沉淀物中过滤出来。在此过程中发现的常见沉积物是食物残渣",
    img: StepFilter,
  },
  {
    title: "第三步：实验室测试",
    desc:
      "您用过的食用油将被出口到处理厂以转化为生物柴油（用于驱动车辆/工业机器）",
    img: StepLab,
  },
]

const customerSegmentPost = [
  {
    title: "家庭住户",
    desc: "我们提供上门收集服务，收集您用过的食用油，重量至少为 5 公斤。",
    img: CustHouse,
  },
  {
    title: "餐厅/酒店",
    desc:
      "我们从您的餐厅或酒店位置收集用过的食用油。根据使用的食用油的数量，将给出更好的购买率。如有需要，可提供油桶储存用过的食用油",
    img: CustRestaurant,
  },
  {
    title: "食品厂",
    desc:
      "我们从您的食品工厂收集用过的食用油。根据使用的食用油的数量，将给出更好的购买率。",
    img: CustFactory,
  },
]

class AboutUsPage extends Component<Props> {
  state = {
    visible: false,
    loading: false,
  }

  render() {
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - 关于我们"
          description="Arus Oil 最初是在马来西亚雪兰莪州梳邦 SS14 巴生谷的试点项目。我们在雪兰莪、森美兰、槟城和 W.P.吉隆坡积极收集用过的食用油。"
        />
        <Header page="关于我们" />
        <LeftContentCard
          image={DoorImage}
          title="关于我们 - Arus Oil"
          content={
            "Arus Oil (MyProtech Sdn. Bhd.) 最初是 SS14 社区中产阶级的试点项目。自 2017 年以来，SS14 居民一直在附近公园的收集箱中处理用过的食用油 (UCO)。 \n\n 这个项目是与邻里建筑区 (KRT) 合作进行的。调查显示，SS14的大部分家庭都经常做饭。于是，产生了回收用过的食用油的想法。与其扔掉你用过的食用油，你可以通过出售它来赚取现金。"
          }
        />
        <RightContentCard
          image={AboutNRE}
          title="我们的愿景"
          content={
            "从 2017 年至今，Arus Oil 收集了约 250 公吨用过的食用油。我们一直循环到今天的原因是因为缺乏不可再生能源。我们世界消费的 72% 由化石燃料（不可再生）提供动力。石油/石油预计将在 2052 年用完。\n\n 我们相信回收用过的食用油有助于减缓不可再生能源的消耗。这是因为收集到的所有用过的食用油都将转化为生物柴油，用于为车辆或工业机器提供动力。 \n\n Arus Oil 在巴生谷（雪兰莪和吉隆坡 - 马来西亚）积极收集用过的食用油。 "
          }
        />
        <CardSegment types={workPosts} title="我们如何运作" />
        <CardSegment types={customerSegmentPost} title="客户细分" />
        <LeftContentCard
          image={AboutBiodiesel}
          title="你用过的食用油会发生什么？"
          content={
            "您用过的食用油将出口到荷兰和英国的处理厂，在那里它将被转化为 ASTM B-20 级生物柴油。有几种类型的生物柴油分离\n\n - B-5 级（用于轻型柴油车）\n\n- B-20 级（用于柴油车或发电机）\n\n- B-100 级（重型车辆，例如火车和飞机）"
          }
        />
        <RightContentCard
          image={AboutMPOB}
          contain={true}
          title="持牌经营者 - Arus Oil  ​"
          content={
            "收集/购买用过的食用油需要 MPOB 许可证。根据 2005 年马来西亚棕榈油委员会许可条例，用过的食用油 (Palm) 被归类为棕榈油 (SPO)。\n\n Arus Oil 是 MPOB 的许可运营商，我们的许可证号为 619709017000。使用 MPOB 许可证运营可确保所有收集的用过的食用油被转化为生物柴油，而不是用于危害社会和环境的非法用途。"
          }
        />
        <SubscribeAction language={Language.CH} />
        <Footer />
      </div>
    )
  }
}

export default AboutUsPage
